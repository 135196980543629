import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';

import { Hero } from '../components/hero';

const PreviewPage: React.FC = () => {
  return (
    <Hero shape="square">
      <h1>Loading preview…</h1>
    </Hero>
  );
};

export default withPrismicPreviewResolver(PreviewPage);
